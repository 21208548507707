import turtles from "../assets/images/turtles.png";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import forwardArrow from "../assets/images/forward.png";
import tattooMachine from "../assets/images/tattoo-machine.png";
import piercing from "../assets/images/piercing.png";
import { Helmet } from "react-helmet";
import customDesign from "../assets/images/design.png";

function Services() {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);

  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Hawaii | Services</title>
      </Helmet>
      <section id="services" className="container mt-60 mb-60">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section_title text-center pb-30">
              <h4 className="title">Services</h4>
              <img className="turtles" src={turtles} alt="turtles" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-12 mt-60 mb-30">
            <div className="card">
              <div className="servicesIcons box-shadow bg-white rounded-circle mx-auto text-center">
                <img
                  className="tattooIcon"
                  src={tattooMachine}
                  alt="tattoo machine"
                />
              </div>
              <div className="card-body text-center">
                <h3 className="card-title pt-1">Tattoos</h3>
                <div className="card-text text-sm">
                  Tattoos are a great way to express yourself and make a
                  statement. We're here to help you get the body art of your
                  dreams. We've been tattooing for years, and we know what it
                  takes to make you feel comfortable and confident in your skin.
                  <br />
                  <Button
                    variant="link"
                    onClick={() => setOpen(!open)}
                    aria-expanded={open}
                    aria-controls="collapse-text"
                  >
                    ... Read More
                  </Button>
                  <Collapse in={open}>
                    <div id="collapse-text">
                      Our artists are skilled, they understand the importance of
                      creating a work of art that reflects your personality and
                      makes you feel like a million bucks. Our gallery has many
                      different styles, so check out our tattoos below!
                    </div>
                  </Collapse>
                </div>
                <Link to="/gallery">
                  <span className="text-sm text-uppercase font-weight-bold">
                    Check out our artwork
                    <img src={forwardArrow} alt="forward arrow" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-60 mb-30">
            <div className="card">
              <div className="servicesIcons box-shadow bg-white rounded-circle mx-auto text-center">
                <img
                  className="piercingIcon"
                  src={piercing}
                  alt="piercing icon"
                />
              </div>
              <div className="card-body text-center">
                <h3 className="card-title pt-1">Piercings</h3>
                <div className="card-text text-sm">
                  Your body is a temple, an art piece. How do you want to
                  decorate it? Sacred Art Tattoo offers body piercing in a
                  professional sterile environment, using only the finest tools
                  and materials tailored to your needs.
                  <br />
                  <Button
                    variant="link"
                    onClick={() => setOpen2(!open2)}
                    aria-expanded={open2}
                    aria-controls="collapse-text2"
                  >
                    ... Read More
                  </Button>
                  <Collapse in={open2}>
                    <div id="collapse-text2">
                      We offer both high-quality implant-grade ASTM-F136
                      Titanium and 316L Stainless Steel, depending on your skin
                      sensitivity and personal preferences. Both materials are
                      bio-compatible, ensuring safe and effective healing. For
                      those with metal allergies, we recommend titanium due to
                      its lightweight, corrosion-resistant properties. Please
                      note that we do not use 3rd party or outside jewelry for
                      safety reasons.
                    </div>
                  </Collapse>
                </div>
                <Link to="/piercing">
                  <span className="text-sm text-uppercase font-weight-bold">
                    CLICK HERE FOR OUR PRICE LIST
                    <img src={forwardArrow} alt="forward arrow" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12 mt-60 mb-30">
            <div className="card">
              <div className="servicesIcons box-shadow bg-white rounded-circle mx-auto text-center">
                <img
                  className="designIcon"
                  src={customDesign}
                  alt="custom design"
                />
              </div>
              <div className="card-body text-center">
                <h3 className="card-title pt-1">Custom Designs</h3>
                <div className="card-text text-sm">
                  You're looking for something special and unique. Something you
                  can't find anywhere else. Something that represents your
                  personality and your story — something that will last forever.
                  We can help you get it.
                  <br />
                  <Button
                    variant="link"
                    onClick={() => setOpen3(!open3)}
                    aria-expanded={open3}
                    aria-controls="collapse-text3"
                  >
                    ... Read More
                  </Button>
                  <Collapse in={open3}>
                    <div id="collapse-text3">
                      We offer custom designs created by our talented artists
                      that are completely unique to you and your needs. Whether
                      you want a simple design or something more complex, we've
                      got you covered.
                    </div>
                  </Collapse>
                </div>
                <Link to="/contact">
                  <span className="text-sm text-uppercase font-weight-bold">
                    Contact us for a custom design
                    <img
                      style={{ marginTop: "-2px" }}
                      src={forwardArrow}
                      alt="forward"
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Services;
