import React from "react";
import Video from "../components/Video";
import Locations from "./Locations";
import Artists from "./Artists";
import About from "./About";
import Services from "./Services";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div className="position-relative">
      <Video />
      <Artists />
      <Services />
      <Locations />
      <About />
      <Helmet>
        <title>Sacred Art Tattoo Hawaii</title>
      </Helmet>
    </div>
  );
}

export default Home;
