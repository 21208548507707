import turtles from "../assets/images/turtles.png";
import artistsList from "../components/ArtistsArray";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import getImages from "../components/GetImages";
import Button from "react-bootstrap/Button";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import "../assets/css/Artists.css";
import LazyLoadImage from "../components/LazyLoadImage";

function Artists() {
  <Fancybox />;
  const [images, setImages] = useState([]);
  const [load, setLoad] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [shuffledArtists, setShuffledArtists] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const images = await getImages();
        setImages(images);
        setLoad(true);
      } catch (error) {
        console.log("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const shuffledList = [...artistsList].sort(() => 0.5 - Math.random());
    setShuffledArtists(shuffledList);
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
      slidesToSlide: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  const ButtonGroup = ({ next, previous, ...rest }) => {
    const {
      carouselState: { currentSlide, totalItems, slidesToShow },
    } = rest;
    return (
      <div className="carousel-button-group">
        <Button
          style={{ marginRight: "20px" }}
          variant="light"
          className={currentSlide === 0 ? "buttonDisable" : ""}
          onClick={() => previous()}
        >
          <FaArrowLeft />
        </Button>
        <Button
          variant="light"
          className={
            currentSlide === totalItems - slidesToShow ? "buttonDisable" : ""
          }
          onClick={() => next()}
        >
          <FaArrowRight />
        </Button>
      </div>
    );
  };

  const toggleDropdown = (index) => {
    if (dropdownOpen === index) {
      setDropdownOpen(null);
    } else {
      setDropdownOpen(index);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sacred Art Tattoo Hawaii | Our Artists</title>
      </Helmet>
      <section id="artists" className="container mt-60 mb-60">
        <div className="row justify-content-center">
          <div className="section_title text-center pb-30">
            <h4 className="title">Our Artists</h4>
            <img className="turtles" src={turtles} alt="turtles" />
          </div>
          <div style={{ textAlign: "center" }} className="pb-30">
            <h3>Click an artist to view their bio & artwork</h3>
          </div>
        </div>
        <div className="carouselArtists">
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={false}
            centerMode={false}
            responsive={responsive}
            ssr={true}
            infinite={false}
            arrows={false}
            renderButtonGroupOutside={true}
            customButtonGroup={<ButtonGroup />}
            customTransition="transform 300ms ease-in-out"
          >
            {load &&
              shuffledArtists.map((artist, index) => {
                const filterImages = images.filter((image) => {
                  return image.includes(artist.files);
                });
                const firstEight = filterImages.slice(0, 8);
                return (
                  <div key={artist.name} className="artist-item">
                    <div className="thumbex">
                      {artist.location2 ? (
                        <div className="custom-dropdown">
                          {dropdownOpen === index && (
                            <div className="dropdown-menu-custom">
                              <a
                                href={artist.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {artist.location}
                              </a>
                              <a
                                href={artist.url2}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {artist.location2}
                              </a>
                            </div>
                          )}
                          <div
                            className="dropdown-toggle-custom"
                            onClick={() => toggleDropdown(index)}
                          >
                            <LazyLoadImage
                              src={artist.pic}
                              alt={artist.name}
                              className="artist-img"
                            />
                            <span>
                              <strong>{artist.name}</strong>
                              <p style={{ color: "#aaa" }}>
                                {artist.location} & {artist.location2}
                              </p>
                            </span>
                          </div>
                        </div>
                      ) : (
                        <a
                          href={artist.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <LazyLoadImage
                            src={artist.pic}
                            alt={artist.name}
                            className="artist-img"
                          />
                          <span>
                            <strong>{artist.name}</strong>
                            <p style={{ color: "#aaa" }}>{artist.location}</p>
                          </span>
                        </a>
                      )}
                    </div>
                    <div className="container">
                      <div className="row">
                        {firstEight.map((item) => {
                          return (
                            <div
                              key={`${artist.name}.${item}`}
                              className="col-6 col-md-3 mb-15 pl-5 pr-5"
                            >
                              <a data-fancybox={artist.name} href={item}>
                                <LazyLoadImage
                                  src={item}
                                  alt="gallery pic"
                                  className="zoom"
                                />
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
          </Carousel>
        </div>
      </section>
    </>
  );
}

export default Artists;
