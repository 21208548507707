import ReactPlayer from "react-player/youtube";

function Video() {
  return (
    <div className="heroVideo">
      <ReactPlayer
        style={{
          position: "absolute",
          top: 0,
          left: 0,
        }}
        url="https://www.youtube.com/embed/DKT3GhrBCzY"
        playing={true}
        loop={true}
        volume={0}
        muted={true}
        width="100%"
        height="100%"
      />
    </div>
  );
}

export default Video;
